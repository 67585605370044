import { action, computed } from '@ember/object';
import { type Registry, service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface DateSelectorSignature {
  // The arguments accepted by the component
  Args: {
    date: Date;
    onChange: (date: Date) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DateSelectorComponent extends Component<DateSelectorSignature> {
  @service declare intl: Registry['intl'];

  @tracked date = this.args.date;

  @action
  nextDate() {
    this.date = this.args.date.setUTCDate(this.args.date.getUTCDate() + 1);
  }

  @action
  prevDate() {
    this.date = this.args.date.setUTCDate(this.args.date.getUTCDate() - 1);
  }
}
