import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import RouterService from '@ember/routing/router-service';

export default class TheLetterIndexRoute extends Route {
  @service declare store: Store;
  @service declare router: RouterService;

  model() {
    const { list_id } = this.paramsFor('the-letter');
    const record = this.store.peekRecord('recipient-list', list_id);

    if (record) {
      return record;
    }

    return this.store.findRecord('recipient-list', list_id);
  }
}
