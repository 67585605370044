import ENV from 'hello-world-leaders/config/environment';

export function initialize(applicationInstance) {
  for (const key in ENV.CONTROLLERS) {
    applicationInstance
      .lookup(`controller:${key}`)
      .setProperties(ENV.CONTROLLERS[key]);
    console.debug(
      '[init:setup-controllers-from-env] Applied ENV to controller:',
      key,
    );
  }
}

export default {
  after: ['i18n'],
  name: 'setup-controllers-from-env',
  initialize,
};
