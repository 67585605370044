import Route from '@ember/routing/route';
import { type Registry, service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';

export default class TheLetterRoute extends Route {
  @service declare store: Store;
  @service declare intl: Registry['intl'];

  model(params) {
    this.store.findAll('recipient-list', {
      include: 'recipients',
    });

    return this.store.findRecord('recipient-list', params.list_id, {
      include: 'recipients',
    });
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    const { list_id } = this.paramsFor(this.routeName);
    const lists = this.store.peekAll('recipient-list');

    controller.set('lists', lists);
    controller.set('listId', list_id);
    controller.set('activeList', lists.findBy('id', list_id));
    controller.set('hasLargePrintPadding', /chrome/i.test(navigator.userAgent));
  }

  @action
  didTransition() {
    gtag('event', 'page_view', {
      page_location: window.location.href,
      language: this.intl.primaryLocale,
    });
  }
}
