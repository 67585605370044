import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';

export default class TheLetterController extends Controller {
  @service declare store: Store;
  @service declare router: RouterService;

  @tracked declare hasLargePrintPadding: boolean;

  @action print() {
    window.print();
  }

  @action changeList(e: Event & { target: HTMLSelectElement }) {
    console.debug('[action:changeList]', e.target.value);
    this.router.transitionTo('the-letter.index', e.target.value);
  }

  @action togglePrintPadding() {
    this.hasLargePrintPadding = !this.hasLargePrintPadding;
  }
}
