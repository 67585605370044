import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { CountryCode } from 'hello-world-leaders/models/leader';
import { action } from '@ember/object';
export default class RecipientsNewRoute extends Route {
  @service store: Store;

  model(params, transition) {
    const record = this.store.createRecord('leader', {
      countryCode: CountryCode.UnitedStates,
    });
    transition.redirect('recipients.edit', record);
  }

  @action
  willTransition(transition) {
    debugger;
    //implement the behavior you want.
    // you can for example abort the transition if a condition is not fullfiled
    // by calling transition.abort().
  }
}
