import Service from '@ember/service';

export default class ThemeService extends Service {
  theme = 'default';
  isRTL = false;

  setTheme(theme: string) {
    this.theme = theme;
  }

  getTheme() {
    return this.theme;
  }

  isRTL() {
    debugger;
    return this.isRTL;
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:theme')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('theme') declare altName: ThemeService;`.
declare module '@ember/service' {
  interface Registry {
    theme: ThemeService;
  }
}
