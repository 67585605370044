import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import Component from '@glimmer/component';

export interface LanguageSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class LanguageSelectorComponent extends Component<LanguageSelectorSignature> {
  @service declare intl: Services['intl'];

  @action
  setLanguage(event: Event) {
    this.intl.setLocale(event.target.value);
  }
}
