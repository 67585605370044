import Component from '@glimmer/component';

export interface FormControlSignature {
  // The arguments accepted by the component
  Args: {
    type: 'range';
    name: 'string';
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FormControlComponent extends Component<FormControlSignature> {
  get type() {
    return this.args.type;
  }

  get name() {
    return this.args.name;
  }
}
