import ENV from 'hello-world-leaders/config/environment';

export function initialize(applicationInstance) {
  const appController = applicationInstance.lookup('controller:application');

  appController.set('googleAnalyticsID', ENV.APP['googleAnalyticsID']);

  // try {
  //   appController.set(
  //     'showConsentBanner',
  //     localStorage.getItem('showConsentBanner') === null,
  //   );
  // } catch (e) {
  //   console.warn(
  //     '[init:setup-ga] failed to read consent settings from localStorage. falling back to cookie',
  //   );
  //   try {
  //     const showConsentBanner = document.cookie
  //       .split(';')
  //       .map(function (token) {
  //         const [cookieName, value] = token.split('=');
  //         return cookieName;
  //       })
  //       .includes('_ga');

  //     appController.set('showConsentBanner', showConsentBanner);
  //   } catch (e) {
  //     console.error(
  //       '[init:setup-ga] failed to read consent settings from cookie',
  //       e,
  //     );
  //   }
  // }

  // try {
  //   const cookiesEnabled = localStorage.getItem('cookiesEnabled');

  //   if (cookiesEnabled) {
  //     appController.set('cookiesEnabled', cookiesEnabled === 'true');
  //   }
  // } catch (e) {
  //   try {
  //     const cookiesEnabled = document.cookie
  //       .split(';')
  //       .map(function (token) {
  //         const [cookieName, value] = token.split('=');
  //         return cookieName;
  //       })
  //       .includes('_ga');

  //     if (cookiesEnabled) {
  //       appController.set('cookiesEnabled', cookiesEnabled);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   console.error(
  //     '[init:setup-ga] failed to read consent settings from cookie',
  //     e,
  //   );
  // }
}

export default {
  name: 'setup-ga',
  initialize,
};
