import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

export default class TheLetterMailingAddressesRoute extends Route {
  @service declare store: Store;

  async model() {
    const { list_id } = this.paramsFor('the-letter');
    const record = await this.store.findRecord('recipient-list', list_id, {
      include: 'recipients',
    });

    return record.get('recipients');
  }
}
