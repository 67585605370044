
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("hello-world-leaders/app", function(){ return i("hello-world-leaders/app.ts");});
d("hello-world-leaders/application/adapter", function(){ return i("hello-world-leaders/application/adapter.js");});
d("hello-world-leaders/application/controller", function(){ return i("hello-world-leaders/application/controller.ts");});
d("hello-world-leaders/application/route", function(){ return i("hello-world-leaders/application/route.ts");});
d("hello-world-leaders/application/serializer", function(){ return i("hello-world-leaders/application/serializer.js");});
d("hello-world-leaders/application/template", function(){ return i("hello-world-leaders/application/template.hbs");});
d("hello-world-leaders/config/environment", function(){ return i("hello-world-leaders/config/environment.js");});
d("hello-world-leaders/cookie-policy/route", function(){ return i("hello-world-leaders/cookie-policy/route.ts");});
d("hello-world-leaders/cookie-policy/template", function(){ return i("hello-world-leaders/cookie-policy/template.hbs");});
d("hello-world-leaders/donate/route", function(){ return i("hello-world-leaders/donate/route.ts");});
d("hello-world-leaders/donate/template", function(){ return i("hello-world-leaders/donate/template.hbs");});
d("hello-world-leaders/error/template", function(){ return i("hello-world-leaders/error/template.hbs");});
d("hello-world-leaders/formats", function(){ return i("hello-world-leaders/formats.js");});
d("hello-world-leaders/gaia/controller", function(){ return i("hello-world-leaders/gaia/controller.ts");});
d("hello-world-leaders/gaia/route", function(){ return i("hello-world-leaders/gaia/route.ts");});
d("hello-world-leaders/gaia/template", function(){ return i("hello-world-leaders/gaia/template.hbs");});
d("hello-world-leaders/humor/route", function(){ return i("hello-world-leaders/humor/route.ts");});
d("hello-world-leaders/humor/template", function(){ return i("hello-world-leaders/humor/template.hbs");});
d("hello-world-leaders/index/route", function(){ return i("hello-world-leaders/index/route.ts");});
d("hello-world-leaders/index/template", function(){ return i("hello-world-leaders/index/template.hbs");});
d("hello-world-leaders/instance-initializers/i18n", function(){ return i("hello-world-leaders/instance-initializers/i18n.ts");});
d("hello-world-leaders/instance-initializers/setup-controllers-from-env", function(){ return i("hello-world-leaders/instance-initializers/setup-controllers-from-env.ts");});
d("hello-world-leaders/instance-initializers/setup-ga", function(){ return i("hello-world-leaders/instance-initializers/setup-ga.ts");});
d("hello-world-leaders/loading/template", function(){ return i("hello-world-leaders/loading/template.hbs");});
d("hello-world-leaders/models/leader", function(){ return i("hello-world-leaders/models/leader.ts");});
d("hello-world-leaders/models/position", function(){ return i("hello-world-leaders/models/position.ts");});
d("hello-world-leaders/models/recipient-list", function(){ return i("hello-world-leaders/models/recipient-list.ts");});
d("hello-world-leaders/privacy-policy/route", function(){ return i("hello-world-leaders/privacy-policy/route.ts");});
d("hello-world-leaders/privacy-policy/template", function(){ return i("hello-world-leaders/privacy-policy/template.hbs");});
d("hello-world-leaders/progress/route", function(){ return i("hello-world-leaders/progress/route.ts");});
d("hello-world-leaders/progress/template", function(){ return i("hello-world-leaders/progress/template.hbs");});
d("hello-world-leaders/recipients/edit/controller", function(){ return i("hello-world-leaders/recipients/edit/controller.ts");});
d("hello-world-leaders/recipients/edit/route", function(){ return i("hello-world-leaders/recipients/edit/route.ts");});
d("hello-world-leaders/recipients/edit/template", function(){ return i("hello-world-leaders/recipients/edit/template.hbs");});
d("hello-world-leaders/recipients/index/controller", function(){ return i("hello-world-leaders/recipients/index/controller.ts");});
d("hello-world-leaders/recipients/index/route", function(){ return i("hello-world-leaders/recipients/index/route.ts");});
d("hello-world-leaders/recipients/index/template", function(){ return i("hello-world-leaders/recipients/index/template.hbs");});
d("hello-world-leaders/recipients/lists/edit/controller", function(){ return i("hello-world-leaders/recipients/lists/edit/controller.ts");});
d("hello-world-leaders/recipients/lists/edit/route", function(){ return i("hello-world-leaders/recipients/lists/edit/route.ts");});
d("hello-world-leaders/recipients/lists/edit/template", function(){ return i("hello-world-leaders/recipients/lists/edit/template.hbs");});
d("hello-world-leaders/recipients/lists/index/controller", function(){ return i("hello-world-leaders/recipients/lists/index/controller.ts");});
d("hello-world-leaders/recipients/lists/index/route", function(){ return i("hello-world-leaders/recipients/lists/index/route.ts");});
d("hello-world-leaders/recipients/lists/index/template", function(){ return i("hello-world-leaders/recipients/lists/index/template.hbs");});
d("hello-world-leaders/recipients/lists/new/controller", function(){ return i("hello-world-leaders/recipients/lists/new/controller.ts");});
d("hello-world-leaders/recipients/lists/new/route", function(){ return i("hello-world-leaders/recipients/lists/new/route.ts");});
d("hello-world-leaders/recipients/lists/new/template", function(){ return i("hello-world-leaders/recipients/lists/new/template.hbs");});
d("hello-world-leaders/recipients/new/route", function(){ return i("hello-world-leaders/recipients/new/route.ts");});
d("hello-world-leaders/recipients/template", function(){ return i("hello-world-leaders/recipients/template.hbs");});
d("hello-world-leaders/router", function(){ return i("hello-world-leaders/router.ts");});
d("hello-world-leaders/services/store", function(){ return i("hello-world-leaders/services/store.js");});
d("hello-world-leaders/services/theme", function(){ return i("hello-world-leaders/services/theme.ts");});
d("hello-world-leaders/terms-of-service/route", function(){ return i("hello-world-leaders/terms-of-service/route.ts");});
d("hello-world-leaders/terms-of-service/template", function(){ return i("hello-world-leaders/terms-of-service/template.hbs");});
d("hello-world-leaders/the-letter/controller", function(){ return i("hello-world-leaders/the-letter/controller.ts");});
d("hello-world-leaders/the-letter/index/controller", function(){ return i("hello-world-leaders/the-letter/index/controller.ts");});
d("hello-world-leaders/the-letter/index/route", function(){ return i("hello-world-leaders/the-letter/index/route.ts");});
d("hello-world-leaders/the-letter/index/template", function(){ return i("hello-world-leaders/the-letter/index/template.hbs");});
d("hello-world-leaders/the-letter/mailing-addresses/controller", function(){ return i("hello-world-leaders/the-letter/mailing-addresses/controller.ts");});
d("hello-world-leaders/the-letter/mailing-addresses/route", function(){ return i("hello-world-leaders/the-letter/mailing-addresses/route.ts");});
d("hello-world-leaders/the-letter/mailing-addresses/template", function(){ return i("hello-world-leaders/the-letter/mailing-addresses/template.hbs");});
d("hello-world-leaders/the-letter/route", function(){ return i("hello-world-leaders/the-letter/route.ts");});
d("hello-world-leaders/the-letter/template", function(){ return i("hello-world-leaders/the-letter/template.hbs");});
d("hello-world-leaders/transforms/boolean", function(){ return i("hello-world-leaders/transforms/boolean.js");});
d("hello-world-leaders/transforms/date", function(){ return i("hello-world-leaders/transforms/date.js");});
d("hello-world-leaders/transforms/number", function(){ return i("hello-world-leaders/transforms/number.js");});
d("hello-world-leaders/transforms/string", function(){ return i("hello-world-leaders/transforms/string.js");});
d("hello-world-leaders/volunteer/route", function(){ return i("hello-world-leaders/volunteer/route.ts");});
d("hello-world-leaders/volunteer/template", function(){ return i("hello-world-leaders/volunteer/template.hbs");});
d("hello-world-leaders/services/page-title", function(){ return i("hello-world-leaders/services/page-title.js");});
d("hello-world-leaders/initializers/ember-cli-mirage", function(){ return i("hello-world-leaders/initializers/ember-cli-mirage.js");});
d("hello-world-leaders/instance-initializers/ember-cli-mirage-autostart", function(){ return i("hello-world-leaders/instance-initializers/ember-cli-mirage-autostart.js");});
d("hello-world-leaders/mirage/config", function(){ return i("hello-world-leaders/mirage/config.js");});
d("hello-world-leaders/mirage/fixtures/leaders", function(){ return i("hello-world-leaders/mirage/fixtures/leaders.js");});
d("hello-world-leaders/mirage/fixtures/positions", function(){ return i("hello-world-leaders/mirage/fixtures/positions.js");});
d("hello-world-leaders/mirage/fixtures/recipient-lists", function(){ return i("hello-world-leaders/mirage/fixtures/recipient-lists.js");});
d("hello-world-leaders/mirage/models/leader", function(){ return i("hello-world-leaders/mirage/models/leader.js");});
d("hello-world-leaders/mirage/models/recipient-list", function(){ return i("hello-world-leaders/mirage/models/recipient-list.js");});
d("hello-world-leaders/mirage/scenarios/default", function(){ return i("hello-world-leaders/mirage/scenarios/default.js");});
d("hello-world-leaders/mirage/serializers/application", function(){ return i("hello-world-leaders/mirage/serializers/application.js");});
d("hello-world-leaders/component-managers/glimmer", function(){ return i("hello-world-leaders/component-managers/glimmer.js");});
d("hello-world-leaders/initializers/app-version", function(){ return i("hello-world-leaders/initializers/app-version.js");});
d("hello-world-leaders/utils/titleize", function(){ return i("hello-world-leaders/utils/titleize.js");});
d("hello-world-leaders/data-adapter", function(){ return i("hello-world-leaders/data-adapter.js");});
d("hello-world-leaders/initializers/ember-data", function(){ return i("hello-world-leaders/initializers/ember-data.js");});
d("hello-world-leaders/services/intl", function(){ return i("hello-world-leaders/services/intl.js");});
d("hello-world-leaders/container-debug-adapter", function(){ return i("hello-world-leaders/container-debug-adapter.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("hello-world-leaders/instance-initializers/setup-fetch", function(){ return i("hello-world-leaders/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"lang":"en","googleAnalyticsID":"G-LW2NJ2SLQZ","name":"hello-world-leaders","version":"0.0.0+03fa5909"});
}

