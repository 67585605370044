import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type Router from 'hello-world-leaders/router';
export default class RecipientsListsNewRoute extends Route {
  @service store: Store | undefined;
  @service router: Router | undefined;

  model() {
    const record = this.store?.createRecord('recipient-list', {
      id: 'some-id-that-doesnt-clash',
      name: 'New List',
    });
    this.router?.transitionTo('recipients.lists.edit', record);
  }
}
