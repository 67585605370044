import EmberRouter from '@ember/routing/router';
import config from 'hello-world-leaders/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('gaia');
  this.route(
    'the-letter',
    { path: '/the-letter/preview/:list_id' },
    function () {
      this.route('mailing-addresses');
    },
  );
  this.route('recipients', { path: '/world-leaders' }, function () {
    this.route('new');
    this.route('lists', function () {
      this.route('new');
      this.route('edit', { path: '/edit/:list_id' });
    });

    this.route('edit', {
      path: '/edit/:list_id',
    });

    this.route('progress');
  });
  this.route('donate', { path: '/%E2%99%A5' });
  this.route('volunteer');
  this.route('privacy-policy');
  this.route('terms-of-service');
  this.route('cookie-policy');
  this.route('humor');
});
