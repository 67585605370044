import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

export default class RecipientsListsNewController extends Controller {
  @service router: RouterService;
  @service store: Store;

  @computed()
  get leaders() {
    return this.store.peekAll('leader');
  }

  @action saveRecord(e) {
    e.preventDefault();
    this.model.save();
    this.router.transitionTo('recipients.lists.index');
  }

  @action cancel() {
    this.model.deleteRecord();
    this.router.transitionTo('recipients.lists.index');
  }

  @action updateRecord(e) {
    if (e.target.name === 'recipient') {
      if (e.target.checked) {
        this.model
          .get('recipients')
          .addObject(this.store.peekRecord('leader', e.target.value));
      } else {
        this.model
          .get('recipients')
          .removeObject(this.store.peekRecord('leader', e.target.value));
      }
    } else {
      this.model.set(e.target.name, e.target.value);
    }
  }
}
