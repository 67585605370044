import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import {
  OpenContactStance,
  CountryCode,
} from 'hello-world-leaders/models/leader';

export default class RecipientsNewController extends Controller {
  @service router: RouterService;

  openContactStanceOptions = OpenContactStance;
  countryCodes = CountryCode;

  @action saveRecord(e) {
    e.preventDefault();
    this.model.save();
    this.router.transitionTo('recipients.index');
  }

  @action cancel() {
    this.model.deleteRecord();
    this.router.transitionTo('recipients.index');
  }

  @action updateRecord(e) {
    this.model.set(e.target.name, e.target.value);
  }
}
