import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface IconSignature {
  // The arguments accepted by the component
  Args: {
    iconName: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class IconComponent extends Component<IconSignature> {
  @tracked iconName = this.args.iconName;
}
