import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { map } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import EmberObject from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';

export default class RecipientsIndexController extends Controller {
  @service declare store: Store;
  @service declare router: RouterService;

  queryParams = [];
  verified = true;

  @map('model', (leader) =>
    EmberObject.create({
      leader,
      isSelected: false,
    }),
  )
  recipients;

  @computed('recipients.@each.isSelected')
  get printDisabled() {
    return this.recipients.isAny('isSelected') === false;
  }

  @action
  select(e: Event & { target: HTMLInputElement }) {
    console.debug(
      '[action:select]',
      'actionType:',
      e.target.checked ? 'add' : 'remove',
      '| id:',
      e.target.name,
    );

    if (e.target.name === 'all') {
      this.recipients.setEach('isSelected', e.target.checked);
    } else {
      const selectedItem = this.recipients.findBy('leader.id', e.target.name);
      selectedItem.set('isSelected', e.target.checked);
    }
  }

  @action
  printPreview() {
    console.debug(
      '[action:printPreview]',
      'selected recipients:',
      this.recipients.filterBy('isSelected', true),
    );

    const recipientList =
      this.store.peekRecord('recipient-list', 'custom') ||
      this.store.createRecord('recipient-list', {
        id: 'custom',
        name: 'Custom Preview',
      });

    recipientList.set(
      'recipients',
      this.recipients.filterBy('isSelected').mapBy('leader'),
    );

    this.router.transitionTo('the-letter.index', recipientList);
  }

  @action
  toggleFilter(e: Event & { target: HTMLInputElement }) {
    const queryParams = {
      verified: this.verified,
    };

    queryParams[e.target.name] = e.target.checked;

    this.router.transitionTo('recipients.index', {
      queryParams,
    });
  }
}
