import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import IntlService from 'ember-intl/services/intl';
export default class ApplicationController extends Controller {
  @service theme: any;
  // Announcement banner
  alertEnabled: boolean = true;

  // Cookies / tracking consent
  @tracked
  cookiesEnabled: boolean = false;
  @tracked
  showConsentBanner: boolean = true;
  @action
  enableCookies(enabled: boolean = false) {
    console.debug(
      '[application:action:enableCookies] cookies enabled:',
      enabled,
    );
    this.cookiesEnabled = enabled;
    this.showConsentBanner = false;

    try {
      localStorage.setItem('showConsentBanner', 'false'); // value is irrelevant, we just check for existence
    } catch (e) {
      console.warn(
        '[application:action:enableCookies] unable to store consent banner state in localStorage',
      );
    }

    try {
      localStorage.setItem('cookiesEnabled', enabled ? 'true' : 'false');
    } catch (e) {
      console.warn(
        '[application:action:enableCookies] unable to store cookiesEnabled state in localStorage',
      );
    }
  }
  googleAnalyticsID: string;
  @action
  initGA() {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      personalization_storage: 'denied',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted',
    });

    gtag('js', new Date());
    gtag('config', this.googleAnalyticsID);
  }

  // end cookies / tracking consent
}
