import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ApplicationRoute extends Route {
  @service theme;
  @service intl;

  @action
  async loading(transition, originRoute) {
    console.log('app route loading');
    // debugger;
    if (this.intl.dir === 'rtl') {
      // RTL
      // debugger;
    } else {
      // NOT RTL
      // debugger;
    }
    let controller = this.controllerFor('application');
    controller.set('isLoading', true);
    transition.promise.finally(function () {
      controller.set('isLoading', false);
    });
  }

  @action
  error(error, transition) {
    console.log('app route error');
    // debugger;
    if (error.status === '403') {
      this.router.replaceWith('login');
    } else {
      // Let the route above this handle the error.
      return true;
    }
  }
}
