import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RecipientsListsEditRoute extends Route {
  @service store;
  model({ list_id }) {
    const record = this.store.peekRecord('recipient-list', list_id);

    if (record) {
      return record;
    }

    return this.store.findRecord('recipient-list', list_id);
  }
}
