import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { type Registry, service } from '@ember/service';
import type Router from 'hello-world-leaders/router';

export default class VolunteerRoute extends Route {
  @service router: Router | undefined;
  @service declare intl: Registry['intl'];

  @action
  didTransition() {
    gtag('event', 'page_view', {
      page_location: window.location.href,
      language: this.intl.primaryLocale,
    });
  }
}
