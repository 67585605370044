import ENV from 'hello-world-leaders/config/environment';
import { run } from '@ember/runloop';

export function initialize(applicationInstance) {
  const intl = applicationInstance.lookup('service:intl');
  const lang = ENV.APP['lang'];
  const rtlLangs = ['zh', 'ja', 'ko', 'ar'];
  const dir = rtlLangs.includes(lang) ? 'rtl' : 'ltr';

  if (intl.locales.includes(lang)) {
    intl.setLocale(lang);
    intl.set('dir', dir);

    console.debug('[init:i18n] ember-intl lang set to', lang);
    console.debug(`[init:i18n] language direction set to: "${dir}"`, lang);
  } else {
    console.debug(
      `[init:i18n] lang "${lang}" not found or unsupported for ember-intl. using fallback`,
    );
  }
}

export default {
  name: 'i18n',
  initialize,
};
