import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';
import { filterBy, union } from '@ember/object/computed';

export default class TheLetterMailingAddressesController extends Controller {
  @computed('model.[]')
  get entries() {
    return this.model.map(function (leader) {
      return EmberObject.create({
        isSelected: true,
        leader,
      });
    });
  }

  @union('entries', 'model') selections;

  @filterBy('entries', 'isSelected', true) selectedEntries;

  @action toggleSelection(index: number) {
    this.entries.objectAt(index).toggleProperty('isSelected');
  }
}
