import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
export default class RecipientsIndexRoute extends Route {
  @service declare store: Store;

  model(params) {
    return this.store.query('leader', params);
  }
}
